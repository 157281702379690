
@media only screen and (max-width: 600px){
.d-flex h2{
	font-size: 1.3rem;
}
.d-flex .btn-thick{
	margin-left: 0rem !important;
	line-height: 1.5;
    padding: .5rem 1rem;
    line-height: 1.5;
    padding: .5rem 1rem!important;
    font-size: 0.675rem;
}
.d-flex .rounded-circle{
	padding: 0px!important;
	line-height: 0.4!important;
	width:22px;
	height:22px;
}
.d-flex .pr-4, .d-flex .mr-4{
	padding-left: 0px;
}
.d-flex .pr-4 span{
	padding-right: 0px!important;
	width:30px!important;
}
.d-flex span i{
	font-size: 0.475rem!important;
}
.d-flex  i.text-muted{
	margin-left: -22px;
	font-size:1rem!important;
}
.card-body{
	padding: 0.5rem!important;
}
.app .padded{
	padding:1rem!important;
}
.d-flex  p{
	min-width:85px;
	
}

}

@media only screen and (max-width: 330px) and (min-width: 300px)  {
.d-flex .pr-4, .d-flex .mr-4{
	width:90px!important
}
}