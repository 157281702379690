@import 'bootstrap/scss/bootstrap';

.app {
  height: 100%;

  .padded {
    padding: 2rem;
  }

  .action-bar-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

.pt-toast-container {
  margin-bottom: 5rem;
}

.pt-toast {
  border-radius: 5px;
  padding: 0;
}

.pt-toast-body {
  margin: 0;
}